import React from "react";
import PropTypes from "prop-types";

function Section({children, color = 'bg-gray-100', className = '', bgImage = null}) {

    let containerStyle = null;
    if (bgImage) {
        //do something with the image
        containerStyle = {
            backgroundImage: `url(${bgImage})`,
        };

        //color = null;
    }

    return (
        <div className={color} style={containerStyle}>
            <section
                className={`flex flex-col flex-1 md:justify-center max-w-6xl mx-auto px-4 py-8 md:p-8 w-full ${className}`}>
                {children}
            </section>
        </div>
    );
}

Section.propTypes = {
    children: PropTypes.node.isRequired
};

export default Section;
