import React from "react";
import PropTypes from "prop-types";
import Section from "./section";

function Page({children, title, className = '', hideTitle = false, bgImage = false, transparentPage = false, subtitle = null}) {

    let h1Component = null;

    if (hideTitle === false) {
        h1Component = (<h1 className={'text-red-800 text-4xl font-bold mb-2'}>{title}</h1>);
    }

    let pageColor = 'bg-white';

    if (transparentPage === true) {
        pageColor = '';
    }


    return (
        <>

            <Section bgImage={bgImage}>
                {h1Component}
                <span className={'mb-2 text-lg text-gray-800'}>{subtitle}</span>
                <div className={` ${pageColor} ${transparentPage === false ? "p-6" : "" } rounded`}>
                    {children}
                </div>
            </Section>
        </>
    );
}

Page.propTypes = {
    children: PropTypes.node.isRequired
};

export default Page;
