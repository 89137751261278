import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import dogIllustration from "../images/dog-illustration.svg";
import Section from "../components/atoms/section";
import Page from "../components/atoms/page";
import Main from "../components/atoms/main";

function AboutPage() {

    const title = 'E-Club Page';
    return (
        <Layout useMain={true}>
            <SEO
                title={title}
                keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
            />
            <Page title={title}>
                Form here
            </Page>
        </Layout>
    );
}

export default AboutPage;
